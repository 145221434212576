import { FunctionComponent } from "react";
import SVGFacebok from "../icons/Facebook.svg?react";
import styles from "./Footer.module.css";
import Icon from "./ui/Icon";

const Footer: FunctionComponent = () => (
  <footer className={styles.footer}>
    {location.pathname === "/" ? (
      <div className={styles.row}>
        <div className={styles.location}>
          <h3 className={styles.title}>HGV Bozen</h3>
          <p>
            Schlachthofstraße 59
            <br />
            39100 Bozen
          </p>
          <p>
            <a href="tel:0471 317 700" className={styles.link}>
              0471 317 700
            </a>
            <br />
            <a href="mailto:info@hgv.it" className={styles.link}>
              info@hgv.it
            </a>
          </p>
        </div>
        <div className={styles.location}>
          <h3 className={styles.title}>HGV Meran</h3>
          <p>
            Gampenstraße 97
            <br />
            39012 Meran
          </p>
          <p>
            <a href="tel:0473 233 144" className={styles.link}>
              0473 233 144
            </a>
            <br />
            <a href="mailto:meran@hgv.it" className={styles.link}>
              meran@hgv.it
            </a>
          </p>
        </div>
        <div className={styles.location}>
          <h3 className={styles.title}>HGV Brixen</h3>
          <p>
            Thalhofergraben 2
            <br />
            39042 Brixen
          </p>
          <p>
            <a href="tel:0472 834 732" className={styles.link}>
              0472 834 732
            </a>
            <br />
            <a href="mailto:brixen@hgv.it" className={styles.link}>
              brixen@hgv.it
            </a>
          </p>
        </div>
        <div className={styles.location}>
          <h3 className={styles.title}>HGV Bruneck</h3>
          <p>
            Europastraße 3F
            <br />
            39031 Bruneck
          </p>
          <p>
            <a href="tel:0474 555 545" className={styles.link}>
              0474 555 545
            </a>
            <br />
            <a href="mailto:bruneck@hgv.it" className={styles.link}>
              bruneck@hgv.it
            </a>
          </p>
        </div>
        <div className={styles.location}>
          <h3 className={styles.title}>HGV Schlanders</h3>
          <p>
            Kapuzinerstraße 36
            <br />
            39031 Schlanders
          </p>
          <p>
            <a href="tel:0473 620 281" className={styles.link}>
              0473 620 281
            </a>
            <br />
            <a href="mailto:schlanders@hgv.it" className={styles.link}>
              schlanders@hgv.it
            </a>
          </p>
        </div>
      </div>
    ) : (
      <p className={styles.text}>
        <span className={styles["text-bold"]}>
          HGV Hoteliers- und Gastwirteverband
        </span>
        <span className={styles.separator}>·</span>
        <span>Schlachthofstraße 59</span>
        <span className={styles.separator}>·</span>
        <span>39100 Bozen</span>
        <span className={styles.separator}>·</span>
        <a href="tel:+39 0471 317 700" className={styles.link}>
          0471 317 700
        </a>
        <span className={styles.separator}>·</span>
        <a href="mailto:info@hgv.it" className={styles.link}>
          info@hgv.it
        </a>
        <span className={styles.separator}>·</span>
        <a
          href="https://www.hgv.it/"
          className={styles.link}
          target="_blank"
          rel="noreferrer"
        >
          www.hgv.it
        </a>
        <span className={styles.separator}>·</span>
        <a
          href="https://www.facebook.com/HGVSuedtirol/"
          className={styles.link}
          target="_blank"
          rel="noreferrer"
        >
          <Icon className={styles.icon} glyph={SVGFacebok} />
          Facebook
        </a>
      </p>
    )}
  </footer>
);

export default Footer;
